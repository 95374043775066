import React, {useEffect, useState} from 'react';
import './style.css';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove'
const Counter = (props) => {
    const {
        initData=0,
        callback=()=>{},
        children,
        name=''
    } = props;

    const [count,setCount] = useState(initData);

    useEffect(()=>{
        setCount(initData);
    },[initData]);
    useEffect(()=>{
        callback(count);
        console.log(count);
        if(count <= 0) {
            setCount(0);
        }
    },[count]);

    return (
        <div className={'search_form__counter_item'}>
            <label htmlFor="" className={'search_form__counter_item_label'}>
                {children}
            </label>
            <div className={'search_form__counter_item_control'}>
                <div className="dicrement" onClick={()=>setCount(count-1)}>
                   <RemoveIcon/>
                </div>

                <input readOnly type="text"   readOnly className="count" value={count} name={name}/>
                <div className="increment" onClick={()=>setCount(count+1)}>
                    <AddIcon/>
                </div>
            </div>
        </div>
    );
};

export default Counter;